export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

export function checkResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    const token = res.headers["x-client-token"];
    if (token) {
      window.axios.defaults.headers.common["X-Client-Token"] = token;
      window.localStorage.setItem("client_token", token);
    }

    return res.data;
  }
}

export function createPhoto(sourceObject, params) {
  params = params || {};

  const formData = new FormData();

  if (sourceObject instanceof File) {
    formData.append("file", sourceObject);
  } else if (typeof sourceObject === "object" && sourceObject.hasOwnProperty("id")) {
    formData.append("photo_id", sourceObject.id);
  } else {
    formData.append("image_url", sourceObject);
  }

  if (params.imageData) {
    formData.append("image_data", JSON.stringify(params.imageData || {}));
  }

  if (params.style) {
    formData.append("style", params.style);
  }

  return window.axios.post(window.appConfig.paths.apiUpload + "/photos/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }).then(checkResponse);
}

export function fetchPhoto(id) {
  return window.axios.get(window.appConfig.paths.api + "/photos/" + id)
    .then(checkResponse);
}

export function fetchRandomPhoto(collageId) {
  return window.axios.get(window.appConfig.paths.api + "/photos/random", {params: {collage_id: collageId}})
    .then(checkResponse);
}

export function submitPhotoTemplate(id, templateId) {
  return window.axios.post(window.appConfig.paths.api + "/photos/" + id + "/template", {template_id: templateId})
    .then(checkResponse);
}

export function createCollage(creativeId, params) {
  return window.axios.post(window.appConfig.paths.api + "/collages/create", {...params, creative_id: creativeId})
    .then(checkResponse);
}

export function attachCreativeToCollage(creativeId, collageHash) {
  return window.axios.post(window.appConfig.paths.api + "/collages/attach", {
    "creative_id": creativeId,
    "collage_hash": collageHash
  }).then(checkResponse);
}

export function subscribeToCollage(creativeId, collageHash, email) {
  return window.axios.post(window.appConfig.paths.api + "/collages/subscribe", {
    "creative_id": creativeId,
    "collage_hash": collageHash,
    "email": email
  }).then(checkResponse);
}

export function fetchCollage(hash, token) {
  return window.axios.get(window.appConfig.paths.api + "/collages/" + hash, {params: {token}})
    .then(checkResponse);
}

export function commitCollageCreativesState(params) {
  return window.axios.post(window.appConfig.paths.api + "/collages/creatives", params)
    .then(checkResponse);
}

export function submitCollageCaption(params) {
  return window.axios.post(window.appConfig.paths.api + "/collages/caption", params)
    .then(checkResponse);
}

export function finalizeCollage(id, token, previewId) {
  return window.axios.post(window.appConfig.paths.api + "/collages/finalize", {id, token, preview_id: previewId})
    .then(checkResponse);
}

export function createCollagePreview(collageHash, creativeId) {
  return window.axios.post(window.appConfig.paths.api + "/collages/preview/create", {
    "collage_hash": collageHash,
    "creative_id": creativeId,
  }).then(checkResponse);
}

export function fillCollagePreview(collageHash, withPlaceholders) {
  return window.axios.post(window.appConfig.paths.api + "/collages/preview/fill", {
    "collage_hash": collageHash,
    "with_placeholders": withPlaceholders ? 1 : 0,
  }).then(checkResponse);
}

export function fetchCollagePreview(id) {
  return window.axios.get(window.appConfig.paths.api + "/collages/preview/" + id)
    .then(checkResponse);
}

export function logCollageShare(id, provider) {
  return window.axios.post(window.appConfig.paths.api + "/collages/log-share", {id, provider})
    .then(checkResponse);
}

export function notifyCollageSubscribers(id, token) {
  return window.axios.post(window.appConfig.paths.api + "/collages/notify-subscribers", {id, token})
    .then(checkResponse);
}

export function logEvent(eventId, eventParams, userParams) {
  return window.axios.post(window.appConfig.analytics.endpoint, {
    id: eventId,
    params: eventParams,
    user_params: userParams,
  }).then(checkResponse);
}
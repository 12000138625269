const VERSION = 1;

const KEY_PREFS = "tmc:prefs";
const KEY_PREFS_VERSION = "tmc:prefs:version";

const KEY_SPLIT_GROUP_ID = "split_group_id";

class ClientStorage {

  constructor() {
    const prefs = window.localStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }

    const currentVersion = parseInt(window.localStorage.getItem(KEY_PREFS_VERSION) || 0);
    if (currentVersion !== VERSION) {
      window.localStorage.setItem(KEY_PREFS_VERSION, VERSION);
    }
  }

  commit() {
    window.localStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---

  getSplitGroupId() {
    return this.getInteger(KEY_SPLIT_GROUP_ID, undefined);
  }

  setSplitGroupId(value) {
    this.setInteger(KEY_SPLIT_GROUP_ID, value);
  }
}

export default new ClientStorage();
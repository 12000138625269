import * as amplitude from "amplitude-js";
import * as api from "./api";

const userProperties = {
  split_group: window.clientConfig.splits.groupId,
};

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({
    ...userProperties,
    is_webview: window.clientConfig.isWebview,
    platform_browser_name: window.clientConfig.platform.name,
    platform_browser_version: window.clientConfig.platform.version,
    platform_os: window.clientConfig.platform.os,
  });
}

// --

export const userEvents = {
  // юзер перешёл в таб в вебвью
  // os - операционная система юзера
  WEBVIEW_TAB_FOCUS: "webview_tab_focus",

  // визит на главную
  PAGE_INDEX: "page_index",

  // визит на форму создания коллажа (после выбора фото)
  PAGE_CREATE: "page_create",

  // визит на форму аттача фото к коллажу (после выбора фото)
  PAGE_ATTACH: "page_attach",

  // визит на страницу коллажа
  // collage_id - наш идентификатор коллажа
  // is_owner - автор или гость
  // ref - откуда пришёл юзер
  PAGE_COLLAGE: "page_collage",
  PAGE_COLLAGE_DOWNLOAD: "page_collage_download",

  // визит на страницу управления коллажом
  // collage_id - наш идентификатор коллажа
  PAGE_MANAGE: "page_manage",

  // визит на транзитную страницу для снепчат вебвью
  // collage_hash - наш хеш коллажа
  PAGE_SNAPCHAT: "page_snapchat",

  // юзер выбрал фото
  // place - расположение кнопки выбора
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  // place - страница
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  // place - страница
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // юзер выбрал превью из предоставленных (вектор)
  // place - страница
  // template_id - идентификатор фотолаб шаблона
  CREATIVE_PREVIEW_SELECT: "creative_preview_select",

  // успешно сгенерили обработку
  CREATIVE_PROCESSED: "creative_processed",

  // неуспешно сгенерили обработку
  CREATIVE_FAILED: "creative_failed",

  // успешно сгенерировали коллаж
  // collage_id - наш идентификатор коллажа
  COLLAGE_PROCESSED: "collage_processed",

  // неуспешно сгенерировали коллаж
  // collage_id - наш идентификатор коллажа
  COLLAGE_FAILED: "collage_failed",

  // юзер подтвердил аттач своей обработки на коллаж
  // collage_id - наш идентификатор коллажа
  COLLAGE_PHOTO_ATTACH: "collage_photo_attach",

  // автор финализировал коллаж
  // collage_id - наш идентификатор коллажа
  COLLAGE_FINALIZE: "collage_finalize",

  // автор изменил подпись для коллажа
  // collage_id - наш идентификатор коллажа
  // caption - текст подписи
  COLLAGE_CAPTION_UPDATE: "collage_caption_update",

  // шаринг страницы коллажа
  // collage_id - наш идентификатор коллажа
  // provider - канал шаринга
  // page - страница с которой был шаринг
  SHARE: "share",

  // скачивание коллажа
  // collage_id - наш идентификатор коллажа
  // is_owner - скачал автор или гость
  // page - страница на которой было скачано
  DOWNLOAD: "download",

  FORM_CREATE_SUBMIT: "form_create_submit",
  FORM_CREATE_EMAIL_SUBMIT: "form_create_email_submit",
};

export const hits = {
  // --
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
      userPropertiesCommitWaited.length = 0;
    }

    api.logEvent(eventId, eventParams, userParams);
  }
}

export function setUserProperty(key, value) {
  userProperties[key] = value;
  userPropertiesCommitWaited.push(key);
}
import Modal from "./Modal";
import i18n from "../i18n";
import React from "react";
import * as api from "../utils/api";
import ErrorModal from "./ErrorModal";

function getShownAtStorageKey(collageId) {
  return `unitecom:subscribers_notify_modal_shown_at:${collageId}`;
}

function getShownAt(collageId) {
  return parseInt(window.localStorage.getItem(getShownAtStorageKey(collageId)) || 0);
}

function setShownAt(collageId) {
  window.localStorage.setItem(getShownAtStorageKey(collageId), "" + Date.now());
}

export default class SubscribersNofityModal extends Modal {

  static shouldBeShown(collage) {
    return collage.is_owner
      && collage.attached_items > 1
      && Date.now() - getShownAt(collage.id) > 60*60*1000;
  }

  handleCancelButtonClick = () => {
    this.dismiss();
  };

  handleSendButtonClick = () => {
    api.notifyCollageSubscribers(this.props.collage.id, this.props.collage.token).then((res) => {
      this.dismiss();
    }).catch((err) => {
      this.context.pushModal(<ErrorModal
        key="SubscribersNofityModal-ErrorModal"
        error={err}
        buttons={ErrorModal.getDefaultReloadButton()}
      />);
    });
  };

  renderModal() {
    setShownAt(this.props.collage.id);

    return <div className="modal__content">
      <h2 className="title-h2 modal__title"
          dangerouslySetInnerHTML={{__html: i18n.t("subscribers_nofity_modal__title")}} />
      <p className="modal__text"
         dangerouslySetInnerHTML={{__html: i18n.t("subscribers_nofity_modal__message")}} />

         <div className="modal__buttons_container">
            <button
             className="btn btn_width-big btn_transparent mr-2"
             children={i18n.t("subscribers_nofity_modal__btn_cancel")}
             onClick={this.handleCancelButtonClick} />
           <button
             className="btn btn_width-big btn_paint ml-2"
             children={i18n.t("subscribers_nofity_modal__btn_send")}
             onClick={this.handleSendButtonClick} />
         </div>
    </div>
  }
}
import React from 'react';
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import {logEvent, userEvents} from "../utils/log";
import i18n from "../i18n";
import {assetUrl, buildScreenSizeImagesPath, buildScreenSizeImagesSrcString} from "../utils/etc";
import LogotypeLink from "../components/LogotypeLink";

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.handleFileSelected = this.handleFileSelected.bind(this);
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);
  }

  handleFileSelected(file) {
    this.props.history.push(routes.CREATE, {file});
  }

  render() {
    const split = window.clientConfig.splits.groupId % 4;

    if (split === 1) {
      return <PageViewV2 handleFileSelected={this.handleFileSelected} />;
    } else if (split === 2) {
      return <PageViewV3 handleFileSelected={this.handleFileSelected}/>;
    } else if (split === 3) {
      return <PageViewV4 handleFileSelected={this.handleFileSelected} />;
    } else {
      return <PageViewDefault handleFileSelected={this.handleFileSelected} />;
    }
  }
}

IndexPage.contextType = AppContext;

function PageViewDefault({handleFileSelected}) {
  const backgroundWebpMobEx = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob_ex.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMobEx = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob_ex.png"), ["1x", "2x", "3x"]);
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page">
    <div className="container">
      <LogotypeLink disabled />
      <div className="main-page__content">
        <h2 className="title-h2 main-page__title"
            dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
        <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
        <FileChooseButton
            className="main-page__btn btn btn_height-big btn_width-big btn_paint"
            children={i18n.t("index_page__select_photo_button")}
            place="IndexPage"
            onFileSelected={handleFileSelected} />
      </div>
      <div className="bubble bubble-ex-large main-page__bubble1" />
      <div className="bubble bubble-small main-page__bubble2" />
      <div className="bubble bubble-large main-page__bubble3" />
      <div className="bubble bubble-small main-page__bubble4" />
      <div className="bubble bubble-medium main-page__bubble5" />
    </div>
    <picture tabIndex="-1">
      <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMobEx)} media="(max-height: 800px) and (max-width: 430px)" type="image/webp" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMobEx)} media="(max-height: 800px) and (max-width: 430px)" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 720px)" type="image/webp" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 720px)" />
      <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
      <img
          className="main-page__bg"
          srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
          src={backgroundPng.base}
          alt="Background" />
    </picture>
  </main>;
}

function PageViewV2({handleFileSelected}) {
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob2.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob2.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page main-page2">
    <div className="container">
      <LogotypeLink disabled />
      <div className="main-page__content-container">
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton
              className="main-page__btn btn btn_height-big btn_width-big btn_paint"
              children={i18n.t("index_page__select_photo_button")}
              place="IndexPage"
              onFileSelected={handleFileSelected} />
        </div>
        <div className="bubble bubble-ex-large main-page__bubble1" />
        <div className="bubble bubble-small main-page__bubble2" />
        <div className="bubble bubble-large main-page__bubble3" />
        <div className="bubble bubble-small main-page__bubble4" />
        <div className="bubble bubble-medium main-page__bubble5" />
        <picture tabIndex="-1">
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 991px)" type="image/webp" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 991px)" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
          <img
              className="main-page__bg"
              srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
              src={backgroundPng.base}
              alt="Background" />
        </picture>
      </div>
    </div>
  </main>;
}

function PageViewV3({handleFileSelected}) {
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob3.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob3.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles3.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles3.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page main-page2 main-page3">
    <div className="container">
      <LogotypeLink disabled />
      <div className="main-page__content-container">
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton
              className="main-page__btn btn btn_height-big btn_width-big btn_paint"
              children={i18n.t("index_page__select_photo_button")}
              place="IndexPage"
              onFileSelected={handleFileSelected} />
        </div>
        <div className="bubble bubble-ex-large main-page__bubble1" />
        <div className="bubble bubble-small main-page__bubble2" />
        <div className="bubble bubble-large main-page__bubble3" />
        <div className="bubble bubble-small main-page__bubble4" />
        <div className="bubble bubble-medium main-page__bubble5" />
        <picture tabIndex="-1">
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 991px)" type="image/webp" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 991px)" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
          <img
              className="main-page__bg"
              srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
              src={backgroundPng.base}
              alt="Background" />
        </picture>
      </div>
    </div>
  </main>;
}

function PageViewV4({handleFileSelected}) {
  const backgroundWebpMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob4.webp"), ["1x", "2x", "3x"]);
  const backgroundPngMob = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles_mob4.png"), ["1x", "2x", "3x"]);
  const backgroundWebp = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.webp"), ["1x", "2x", "3x"]);
  const backgroundPng = buildScreenSizeImagesPath(assetUrl("assets/img/bg/img_puzzles2.png"), ["1x", "2x", "3x"]);

  return <main className="main-section main-page main-page2 main-page4">
    <div className="container">
      <LogotypeLink disabled />
      <div className="main-page__content-container">
        <div className="main-page__content">
          <h2 className="title-h2 main-page__title"
              dangerouslySetInnerHTML={{__html: i18n.t("index_page__greeting")}} />
          <p className="main-page__text" dangerouslySetInnerHTML={{__html: i18n.t("index_page__text")}} />
          <FileChooseButton
              className="main-page__btn btn btn_height-big btn_width-big btn_paint"
              children={i18n.t("index_page__select_photo_button")}
              place="IndexPage"
              onFileSelected={handleFileSelected} />
        </div>
        <div className="bubble bubble-ex-large main-page__bubble1" />
        <div className="bubble bubble-small main-page__bubble2" />
        <div className="bubble bubble-large main-page__bubble3" />
        <div className="bubble bubble-small main-page__bubble4" />
        <div className="bubble bubble-medium main-page__bubble5" />
        <picture tabIndex="-1">
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebpMob)} media="(max-width: 991px)" type="image/webp" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundPngMob)} media="(max-width: 991px)" />
          <source srcSet={buildScreenSizeImagesSrcString(backgroundWebp)} type="image/webp" />
          <img
              className="main-page__bg"
              srcSet={buildScreenSizeImagesSrcString(backgroundPng)}
              src={backgroundPng.base}
              alt="Background" />
        </picture>
      </div>
    </div>
  </main>;
}
import React from "react";
import AppContext from "../contexts/AppContext";
import Loading from "../components/Loading";
import {generatePath} from "react-router";
import routes from "../routes";
import FileChooseButton from "../components/FileChooseButton";
import {SvgSprite} from "../components/SvgSprite";
import ImageView from "../components/ImageView";
import * as api from "../utils/api";
import * as collageUtils from "../utils/collage";
import {openWindow} from "../utils/window";
import ErrorModal from "../components/ErrorModal";
import i18n from "../i18n";
import {ApiResponseError} from "../utils/api";
import {apiResponseErrorCodes} from "../utils/api";
import {copyTextWithInvisibleElement, getLocationQueryObject} from "../utils/text";
import {logEvent, userEvents} from "../utils/log";
import LogotypeLink from "../components/LogotypeLink";
import {CopyToast} from "../components/CopyToast";
import {webviewShare, webviewShareDownload, webviewShareFacebook} from "../utils/webview";
import SubscribersNofityModal from "../components/SubscribersNofityModal";

const FETCH_INTERVAL = 1000;

export default class CollagePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      collage: null,
      copyToastIsVisible: false,
      collageCaption: "",
    };

    this.fetchTimer = null;
  }

  componentDidMount() {
    this.fetchCollage();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  fetchCollage = () => {
    api.fetchCollage(this.props.match.params.hash)
      .then((res) => this.handleCollage(res.collage))
      .catch(this.handleError)
  };

  handleCollage = (collage) => {
    if (this.state.collage === null) {
      const query = getLocationQueryObject();

      logEvent(userEvents.PAGE_COLLAGE, {
        collage_id: collage.id,
        is_owner: collage.is_owner,
        ref: query.ref || undefined,
      });
    }

    const nextState = {
      collageCaption: collage.caption,
      collage
    };

    if (collageUtils.isProcessed(collage)) {
      nextState.isLoading = false;
    } else if (collageUtils.isFailed(collage)) {
      this.handleCollageError(collage);
    } else {
      this.fetchTimer = setTimeout(this.fetchCollage, FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleCollageError = (collage) => {
    this.context.pushModal(<ErrorModal
      key="CollagePage-ErrorModal"
      buttons={ErrorModal.getDefaultReloadButton()}
    />);
  };

  handleError = (err) => {
    if (window.appConfig.isDebug) {
      console.error(err);
    }

    const props = {
      key: "CollagePage-ErrorModal",
      buttons: <React.Fragment>
        <button
          className="btn btn_width-big btn_paint"
          children={i18n.t("error_modal__reload_page")}
          onClick={() => window.location.reload()} />
      </React.Fragment>,
    };

    if (err instanceof ApiResponseError) {
      switch (err.code) {
        case apiResponseErrorCodes.NOT_FOUND: {
          props.message = i18n.t("collage_page__collage_not_found");
          break;
        }
        case apiResponseErrorCodes.DELETED: {
          props.message = i18n.t("collage_page__collage_is_deleted");
          break;
        }
        default:
          break;
      }
    }

    this.context.pushModal(<ErrorModal {...props} />);
  };

  handleManageButtonClick = () => {
    this.props.history.push(generatePath(routes.MANAGE, {
      hash: this.state.collage.hash,
      token: this.state.collage.token
    }));
  };

  handleShareToFacebookButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      provider: "facebook",
      page: "collage",
    });

    api.logCollageShare(this.state.collage.id, "facebook").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "facebook");

    if (window.clientConfig.isWebview) {
      webviewShareFacebook(shareUrl.toString(), this.state.collage.file.url);
    } else {
      openWindow(
        `https://www.facebook.com/dialog/share?app_id=${window.appConfig.facebook.appId}`
          + `&display=popup&href=${encodeURIComponent(shareUrl.toString())}`,
        "Facebook Share"
      );
    }
  };

  handleShareToSnapchatButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      provider: "snapchat",
      page: "collage",
    });

    api.logCollageShare(this.state.collage.id, "snapchat").catch(() => {/* ignore */});

    const shareUrl = new URL(this.state.collage.urls.share);
    shareUrl.searchParams.set("ref", "snapchat");

    if (window.clientConfig.isWebview) {
      // webviewShareSnapchatImage(shareUrl.toString(), this.state.collage.file.url);
      const sw = window.clientConfig.isWebviewIOS
        ? window.screen.width
        : Math.floor(window.screen.width * (window.devicePixelRatio || 1));

      webviewShare({
        providers: "[11]",
        caption_text: "",
        sticker_url: encodeURIComponent(this.state.collage.file.url),
        attachment_url: encodeURIComponent(shareUrl),
        sc_sticker_posx: 0.5,
        sc_sticker_posy: 0.5,
        sc_sticker_width: Math.floor(sw * 0.7),
        sc_sticker_height: Math.floor(sw * 0.7),
        sc_sticker_rotation: 0.0,
      });
    } else {
      if (this.snapchatShareButton) {
        this.snapchatShareButton.click();
      } else {
        this.snapchatShareButton = document.createElement("button");
        this.snapchatShareButton.setAttribute("data-share-url", shareUrl.toString());
        window.snap.creativekit.initalizeShareButtons([this.snapchatShareButton]);
        setTimeout(() => this.snapchatShareButton.click(), 200);
      }
    }
  };

  handleCopyLinkButtonClick = () => {
    logEvent(userEvents.SHARE, {
      collage_id: this.state.collage.id,
      provider: "copylink",
      page: "collage",
    });

    const url = new URL(this.state.collage.urls.share);
    url.searchParams.set("ref", "link");

    copyTextWithInvisibleElement(url);

    this.setState({copyToastIsVisible: true});
    this.copyToastTimer = setTimeout(this.handleCopyToastClose, 3000);
  };

  handleCopyToastClose = () => {
    this.setState({copyToastIsVisible: false});
    clearTimeout(this.copyToastTimer);
  };

  handleDownloadButtonClick = () => {
    logEvent(userEvents.DOWNLOAD, {
      collage_id: this.state.collage.id,
      is_owner: this.state.collage.is_owner,
      page: "collage",
    });

    if (SubscribersNofityModal.shouldBeShown(this.state.collage)) {
      this.context.pushModal(<SubscribersNofityModal
        key="CollagePage-SubscribersNofityModal"
        collage={this.state.collage}
      />);
    }

    if (window.clientConfig.isWebview) {
      webviewShareDownload(this.state.collage.file.url);
    } else {
      window.location.href = this.state.collage.urls.download;
    }
  };

  handleAttachFileSelected = (file) => {
    this.props.history.push(routes.ATTACH, {file, collage: this.state.collage});
  };

  handleCreateNewCollageFileSelected = (file) => {
    this.props.history.push(routes.CREATE, {file});
  };

  renderActionsButtonsForGuest = () => {
    return <div className="result-page__action-btns result-page__action-btns_guest result-page_collage-owner">
      <div className="share-container share-container_small">
        <h4 className="share-container__title" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__share_label")}} />
        <button
          className="share-container__btn"
          onClick={this.handleCopyLinkButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-copy" />
        </button>
        <button
          hidden={!this.context.installedApps.facebook}
          className="share-container__btn"
          onClick={this.handleShareToFacebookButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-fb" />
        </button>
        <button
          hidden={window.clientConfig.isWeb || !this.context.installedApps.snapchat}
          className="share-container__btn"
          onClick={this.handleShareToSnapchatButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-snapchat" />
        </button>
        {/* гость может скачать, только когда нет превью файла (превью файл есть только когда в коллаже есть селебы) */}
        <button
          hidden={this.state.collage.preview_file}
          className="share-container__btn"
          onClick={this.handleDownloadButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 64 64" icon="icon-download-big" />
        </button>
      </div>
    </div>
  };

  renderActionsButtonsForOwner = () => {
    return <div className="result-page__action-btns">
      <div>
        <FileChooseButton
          className="result-page__action-btn btn btn_small btn_text"
          place="CollagePage_NewCollage_Owner"
          onFileSelected={this.handleCreateNewCollageFileSelected}>
          <SvgSprite className="result-page__action-btn-icon" viewBox="0 0 16 16" icon="icon-reload" />
          {i18n.t("collage_page__new_collage_button")}
        </FileChooseButton>
      </div>
      <button
        className="result-page__moderate-btn btn btn_small btn_transparent"
        children={i18n.t("collage_page__moderate_button")}
        onClick={this.handleManageButtonClick} />
    </div>;
  };

  renderLeftColumnForOwner = () => {
    const text1 = this.state.collage.is_finalized
      ? i18n.t("collage_page__owner_text_1_finalized")
      : i18n.t("collage_page__owner_text_1");
    const text2 = this.state.collage.is_finalized
      ? i18n.t("collage_page__owner_text_2_finalized")
      : i18n.t("collage_page__owner_text_2");

    return <div className="result-page__column result-page__column_1">
      <h2 className="title-h2 result-page__title" dangerouslySetInnerHTML={{__html: text1}} />
      <p className="result-page__text" dangerouslySetInnerHTML={{__html: text2}} />

      <div className="caption-form owner-desktop">
        <form className="caption-form" onSubmit={this.handleCaptionFormSubmit}>
          <h3 className="caption-form__title" dangerouslySetInnerHTML={{__html: i18n.t("caption_form_title")}}/>
          <div className="caption-form__container">
            <input className="caption-form__input" type="text" maxLength="250" value={this.state.collageCaption} onChange={this.handleCaptionChanged}/>
            <button className="caption-form__button btn btn_small btn_paint" dangerouslySetInnerHTML={{__html: i18n.t("caption_form_button")}}/>
          </div>
        </form>
      </div>

      <div className="share-container">
        <h4 className="share-container__title" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__share_save__label")}} />
        <button
          className="result-page__action-btn btn btn_small btn_text share-container__btn-download"
          onClick={this.handleDownloadButtonClick}>
          <SvgSprite className="result-page__action-btn-icon" viewBox="0 0 16 16" icon="icon-download" />
        </button>
        <button
          hidden={!this.context.installedApps.facebook}
          className="share-container__btn"
          onClick={this.handleShareToFacebookButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-fb" />
        </button>
        <button
          hidden={window.clientConfig.isWeb || !this.context.installedApps.snapchat}
          className="share-container__btn"
          onClick={this.handleShareToSnapchatButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-snapchat" />
        </button>
        <button
          className="share-container__btn"
          onClick={this.handleCopyLinkButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-copy" />
        </button>
        {/* <button
          className="share-container__btn"
          onClick={this.handleDownloadButtonClick}>
          <SvgSprite className="share-container__btn-icon" viewBox="0 0 80 80" icon="icon-download-big" />
        </button> */}
      </div>

      <p className="result-page__text owner-desktop" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__owner_text_3")}} />
    </div>;
  };

  renderLeftColumnForGuest = () => {
    const text1 = this.state.collage.is_finalized
      ? i18n.t("collage_page__guest_title_1_finalized")
      : i18n.t("collage_page__guest_title_1");
    const text2 = this.state.collage.is_finalized
      ? i18n.t("collage_page__guest_subtitle_1_finalized")
      : i18n.t("collage_page__guest_subtitle_1");

    return <React.Fragment>
      <div className="result-page__content-container result-page__column_1  result-page__column_1-1">
        <h2 className="title-h2 result-page__title" dangerouslySetInnerHTML={{__html: text1}} />
        <p className="result-page__text" dangerouslySetInnerHTML={{__html: text2}} />
        <FileChooseButton
          hidden={this.state.collage.is_finalized}
          className="btn btn_width-big btn_paint result-page-btn_upload-collage-owner"
          children={i18n.t("collage_page__attach_guest_photo_button")}
          place="CollagePage_AttachPhoto"
          onFileSelected={this.handleAttachFileSelected} />
      </div>
      <div className="result-page__content-container result-page__column_1  result-page__column_1-2">
        <h3 className="title-h3" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__guest_title_2")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("collage_page__guest_subtitle_2")}} />

        <FileChooseButton
          className="btn btn_width-big btn_transparent mt-2"
          children={i18n.t("collage_page__create_new_collage_button")}
          place="CollagePage_NewCollage_Guest"
          onFileSelected={this.handleCreateNewCollageFileSelected} />
      </div>
    </React.Fragment>;
  };

  handleCaptionChanged = (e) => {
    this.setState({collageCaption: e.target.value});
  };

  handleCaptionFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.collageCaption !== this.state.collage.caption) {
      logEvent(userEvents.COLLAGE_CAPTION_UPDATE, {
        collage_id: this.state.collage.id,
        caption: this.state.collageCaption,
      });

      const params = {
        id: this.state.collage.id,
        token: this.state.collage.token,
        caption: this.state.collageCaption,
      };

      this.setState({
        isLoading: true,
      }, () => {
        api.submitCollageCaption(params)
            .then(this.handleCollage)
            .catch(this.handleError);
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    const collageFile = this.state.collage.is_owner
      ? (this.state.collage.caption_file || this.state.collage.file)
      : (this.state.collage.preview_caption_file || this.state.collage.preview_file || this.state.collage.file);

    // селеб показываем только для гостей если есть превью файл (превью всегда с селебами)
    // и только для автора когда он один на фото (т.е. file.id == preview_file.id)
    const celebsIsVisible = (this.state.collage.is_owner && this.state.collage.preview_file && this.state.collage.file.id === this.state.collage.preview_file.id)
      || (!this.state.collage.is_owner && this.state.collage.preview_file);

    return <main className="main-section result-page">
      <div className="container">
        <LogotypeLink />

        <div className={"result-page__content " + (!this.state.collage.is_owner ? "guest" : "owner")}>
          {this.state.collage.is_owner
            ? this.renderLeftColumnForOwner()
            : this.renderLeftColumnForGuest()}
          
          <div className="result-page__column result-page__column_2">
            <div>
              <div className="result-page__collage-title-container" hidden={!celebsIsVisible}>
                <h3 className="result-page__collage-title" dangerouslySetInnerHTML={{__html: i18n.t("result_page__collage_title")}} />
                <p className="result-page__collage-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("result_page__collage_subtitle")}} />
              </div>
              <ImageView
                hostClassName="result-page__collage"
                image={collageFile}
                backgrounded />
              {this.state.collage.is_owner
                ? this.renderActionsButtonsForOwner()
                : null/* this.renderActionsButtonsForGuest() */}
            </div>
          </div>

          {this.state.collage.is_owner ?
            <React.Fragment>
              <form className="caption-form" onSubmit={this.handleCaptionFormSubmit}>
              <div className="caption-form owner-mob">
                <h3 className="caption-form__title" dangerouslySetInnerHTML={{__html: i18n.t("caption_form_title")}}/>
                <div className="caption-form__container">
                  <input className="caption-form__input" type="text" maxLength="250" value={this.state.collageCaption} onChange={this.handleCaptionChanged}/>
                  <button className="caption-form__button btn btn_small btn_paint" dangerouslySetInnerHTML={{__html: i18n.t("caption_form_button")}}/>
                </div>
              </div>
              </form>

              <p className="result-page__text owner-mob" dangerouslySetInnerHTML={{__html: i18n.t("collage_page__owner_text_3")}} />
            </React.Fragment>
            : null 
          }

        </div>
      </div>

      {this.state.copyToastIsVisible && <CopyToast onCloseButtonClick={this.handleCopyToastClose} />}
    </main>;
  }
}

CollagePage.contextType = AppContext;
import React from "react";
import {generatePath} from "react-router";
import routes from "../routes";
import {appUrl} from "../utils/etc";
import {copyTextWithInvisibleElement} from "../utils/text";
import {logEvent, userEvents} from "../utils/log";
import i18n from "../i18n";

export class SnapchatTransitionPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      url: ""
    };
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_SNAPCHAT, {
      collage_hash: this.props.match.params.hash,
    });

    this.setState({
      url: appUrl(generatePath(routes.COLLAGE, {hash: this.props.match.params.hash}))
    });
  }

  handleCopyLinkButtonClick = () => {
    copyTextWithInvisibleElement(this.state.url);
  };

  render() {
    return <main className="main-section snapchat-transition-page">
      <div className="container">
        <h2
          className="title-h2 snapchat__text mb-4"
          dangerouslySetInnerHTML={{__html: i18n.t("snapchat_page__text")}} />
        <input
          className="form__input form__email mb-4"
          type="email"
          value={this.state.url}
          readOnly />
        <button
          className="btn btn_paint"
          onClick={this.handleCopyLinkButtonClick}>Copy</button>
      </div>
    </main>;
  }
}
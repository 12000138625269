import {getLocationQueryObject} from "./utils/text";
import {detect} from "detect-browser";
import clientStorage from "./utils/client-storage";

const query = getLocationQueryObject();

window.clientConfig = {
  platform: detect(),
  isSnapchatWebview: window.navigator.userAgent.indexOf("Snapchat") > -1,
  isWeb: typeof query["aid"] === "undefined" && typeof query["vicman_unified_id"] === "undefined",
  isWebview: typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined",
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  token: query["aid"] || query["vicman_unified_id"] || window.localStorage.getItem("client_token") || undefined,
  locale: query["locale"] || query["lang"] || "en",
  splits: {
    groupId: undefined,
  },
};

if (typeof window.clientConfig.token !== "undefined") {
  window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;
}

// --

if (!window.clientConfig.splits.groupId || window.clientConfig.splits.groupId < 0 || window.clientConfig.splits.groupId > 10) {
  if (window.clientConfig.isWebview) {
    const aid = query["aid"] || query["vicman_unified_id"];
    const num = parseInt(aid.substring(aid.length - 2), 16);
    const group = Math.floor(num / (255 / 10)) + 1;

    window.clientConfig.splits.groupId = Math.min(group, 10); // fix for ff => 11 group
  } else {
    const groupId = query["split_group"] || clientStorage.getSplitGroupId();
    if (groupId) {
      window.clientConfig.splits.groupId = parseInt(groupId);
    } else {
      window.clientConfig.splits.groupId = Math.floor(Math.random() * 10) + 1;
    }

    clientStorage.setSplitGroupId(window.clientConfig.splits.groupId);
  }
}

// --

window.appConfig = {
  isDebug: process.env.NODE_ENV.startsWith("prod") === false
    || process.env.REACT_APP_DEBUG === "true"
    || query["vicman_debug"]
    || false,

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
  },

  amplitude: {
    isEnabled: process.env.REACT_APP_AMPLITUDE_ENABLED === "true",
    key: process.env.REACT_APP_AMPLITUDE_KEY,
  },

  analytics: {
    isEnabled: process.env.REACT_APP_ANALYTICS_ENABLED === "true",
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
  },

  hits: {
    isEnabled: process.env.REACT_APP_HITS_ENABLED === "true",
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
  },

  facebook: {
    appId: 140586622674265, // unite.com old
  }
};
import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import IndexPage from "./pages/IndexPage";
import CreatePage from "./pages/CreatePage";
import CollagePage from "./pages/CollagePage";
import ManagePage from "./pages/ManagePage";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import * as webviewUtils from "./utils/webview";
import {logEvent, userEvents} from "./utils/log";
import AttachPage from "./pages/AttachPage";
import {SnapchatTransitionPage} from "./pages/SnapchatTransitionPage";
import CollageDownloadPage from "./pages/CollageDownloadPage";

class App extends React.Component {

  constructor(props) {
    super(props);

    this.allowHosts = [
      "alpha.unite.com", // prod
      "unite-com.photo-cdn.net", // prod
      "unitecom.test3.robothumb.com", // test
      "unitecom-marvel.test3.robothumb.com",
      "localhost:3000", // local node serve
    ];
  }

  componentDidMount() {
    const bodyClasses = [];
    bodyClasses.push("app-locale-" + window.clientConfig.locale);
    window.clientConfig.isWebview && bodyClasses.push("app-webview");
    window.clientConfig.isWebviewAndroid && bodyClasses.push("app-webview-android");
    window.clientConfig.isWebviewIOS && bodyClasses.push("app-webview-ios");
    window.clientConfig.isWeb && bodyClasses.push("app-web");
    window.document.body.classList.add(...bodyClasses);

    if (window.clientConfig.isWebview) {
      window.webviewHolders.tabSelected.subscribe((v) => {
        if (v > 0) {
          logEvent(userEvents.WEBVIEW_TAB_FOCUS, {
            os: window.clientConfig.isWebviewAndroid
              ? "android"
              : (window.clientConfig.isWebviewIOS ? "ios" : "other")
          });
        }
      }, true);

      webviewUtils.webviewCheckInstalledApps(this.context.setInstalledApps);
    }
  }

  render() {
    if (this.allowHosts.indexOf(window.location.host) === -1) {
      return <div />;
    }

    return <div>
      <div className="root-router-host">
        <Switch>
          <Route exact path={routes.INDEX} render={props => <IndexPage {...props} />} />
          <Route exact path={routes.CREATE} render={props => <CreatePage {...props} />} />
          <Route exact path={routes.ATTACH} render={props => <AttachPage {...props} />} />
          <Route exact path={routes.COLLAGE} render={props => <CollagePage {...props} />} />
          <Route exact path={routes.COLLAGE_DOWNLOAD} render={props => <CollageDownloadPage {...props} />} />
          <Route exact path={routes.MANAGE} render={props => <ManagePage {...props} />} />
          <Route exact path={routes.SNAPCHAT_TRANSITION} render={props => <SnapchatTransitionPage {...props} />} />
        </Switch>
      </div>
      <ModalWrapper history={this.props.history} />
    </div>;
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
